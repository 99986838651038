
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexBlGspsODYwMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/index.vue?macro=true";
import { default as kvitteringgyECSdLFzuMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/kvittering.vue?macro=true";
import { default as oppsummeringzbZiDULlEPMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/oppsummering.vue?macro=true";
import { default as handlekurvOhO721kf9lMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv.vue?macro=true";
import { default as _91slug_93zoK2PRgYCOMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/produktkatalog/[slug].vue?macro=true";
import { default as indexfoBBGqcGAbMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/produktkatalog/index.vue?macro=true";
import { default as bruk_45av_45informasjonskapslermhj42ImYfAMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/bruk-av-informasjonskapsler.vue?macro=true";
import { default as brukeravtalewgeRwDDzVFMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/brukeravtale.vue?macro=true";
import { default as abonnementHbfwPTAGARMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/abonnement.vue?macro=true";
import { default as administratoreraVyIcLdyZQMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/administratorer.vue?macro=true";
import { default as bruk3BKB1ZcCEcMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/bruk.vue?macro=true";
import { default as fakturahNNTSRwW2zMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/faktura.vue?macro=true";
import { default as indexarXeMaALgCMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/index.vue?macro=true";
import { default as referansekodeysYF363aRHMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/referansekode.vue?macro=true";
import { default as din_45kontoaACeP0SjyVMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto.vue?macro=true";
import { default as _91slug_93igSej3vVWGMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/forfattere/[slug].vue?macro=true";
import { default as indexXFPA3IuRfDMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/forfattere/index.vue?macro=true";
import { default as indexCOwcLnfSJjMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/index.vue?macro=true";
import { default as logg_45inn2KpDgbouIZMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/logg-inn.vue?macro=true";
import { default as _91_46_46_46lovId_933DeAru92aiMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/lover-og-lovkommentarer/[...lovId].vue?macro=true";
import { default as indexQdj291XWi4Meta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/lover-og-lovkommentarer/index.vue?macro=true";
import { default as personvernKtBhKwd5ZAMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/personvern.vue?macro=true";
import { default as rettsomraderFt6yzw9zW9Meta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/rettsomrader.vue?macro=true";
export default [
  {
    name: "handlekurv-layout",
    path: "/handlekurv",
    meta: handlekurvOhO721kf9lMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv.vue"),
    children: [
  {
    name: "handlekurv",
    path: "",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/index.vue")
  },
  {
    name: "handlekurv-kvittering",
    path: "kvittering",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/kvittering.vue")
  },
  {
    name: "handlekurv-oppsummering",
    path: "oppsummering",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/oppsummering.vue")
  }
]
  },
  {
    name: "produktkatalog-slug",
    path: "/produktkatalog/:slug()",
    meta: _91slug_93zoK2PRgYCOMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/produktkatalog/[slug].vue")
  },
  {
    name: "produktkatalog",
    path: "/produktkatalog",
    meta: indexfoBBGqcGAbMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/produktkatalog/index.vue")
  },
  {
    name: "bruk-av-informasjonskapsler",
    path: "/bruk-av-informasjonskapsler",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/bruk-av-informasjonskapsler.vue")
  },
  {
    name: "brukeravtale",
    path: "/brukeravtale",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/brukeravtale.vue")
  },
  {
    name: "din-konto-layout",
    path: "/din-konto",
    meta: din_45kontoaACeP0SjyVMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto.vue"),
    children: [
  {
    name: "din-konto-abonnement",
    path: "abonnement",
    meta: abonnementHbfwPTAGARMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/abonnement.vue")
  },
  {
    name: "din-konto-administratorer",
    path: "administratorer",
    meta: administratoreraVyIcLdyZQMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/administratorer.vue")
  },
  {
    name: "din-konto-bruk",
    path: "bruk",
    meta: bruk3BKB1ZcCEcMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/bruk.vue")
  },
  {
    name: "din-konto-faktura",
    path: "faktura",
    meta: fakturahNNTSRwW2zMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/faktura.vue")
  },
  {
    name: "din-konto",
    path: "",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/index.vue")
  },
  {
    name: "din-konto-referansekode",
    path: "referansekode",
    meta: referansekodeysYF363aRHMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/referansekode.vue")
  }
]
  },
  {
    name: "forfattere-slug",
    path: "/forfattere/:slug()",
    meta: _91slug_93igSej3vVWGMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/forfattere/[slug].vue")
  },
  {
    name: "forfattere",
    path: "/forfattere",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/forfattere/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexCOwcLnfSJjMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/index.vue")
  },
  {
    name: "logg-inn",
    path: "/logg-inn",
    meta: logg_45inn2KpDgbouIZMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/logg-inn.vue")
  },
  {
    name: _91_46_46_46lovId_933DeAru92aiMeta?.name ?? "lover-og-lovkommentarer-lovId",
    path: "/lover-og-lovkommentarer/:lovId(.*)*",
    meta: _91_46_46_46lovId_933DeAru92aiMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/lover-og-lovkommentarer/[...lovId].vue")
  },
  {
    name: "lover-og-lovkommentarer",
    path: "/lover-og-lovkommentarer",
    meta: indexQdj291XWi4Meta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/lover-og-lovkommentarer/index.vue")
  },
  {
    name: "personvern",
    path: "/personvern",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/personvern.vue")
  },
  {
    name: "rettsomrader",
    path: "/rettsomrader",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/rettsomrader.vue")
  }
]