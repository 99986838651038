import validate from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/pages/runtime/validate.js";
import refresh_45token_45global from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/middleware/refresh-token.global.ts";
import manifest_45route_45rule from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  refresh_45token_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "redirect-if-authenticated": () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/middleware/redirect-if-authenticated.ts")
}