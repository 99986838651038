import revive_payload_client_4sVQNw7RlN from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_U4BUIcs89R from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import plugin_tMGwffvjBc from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import session_client_NxDDSatxox from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import siteConfig_JRId4KOeUL from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_JSh5nGhzCz from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eoILE7jqvj from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_client_OVoKJro5pc from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import a11y_client_0xrnkKCxbb from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/a11y.client.ts";
import core_fetch_Ny5VEdu3HH from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/core-fetch.ts";
import datadog_client_7ANTQbYUMg from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/datadog.client.ts";
import error_handler_xwgO4KJDpl from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/error-handler.ts";
import ks_toast_client_0cfbG3g52O from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/ks-toast.client.ts";
import vue_query_6kKch8dzYS from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/vue-query.ts";
import defaults_M8ptihKv0z from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_zlvi6dcIsi,
  prefetch_client_5tzzN0oIVL,
  plugin_U4BUIcs89R,
  plugin_tMGwffvjBc,
  session_client_NxDDSatxox,
  siteConfig_JRId4KOeUL,
  inferSeoMetaPlugin_JSh5nGhzCz,
  titles_eoILE7jqvj,
  plugin_client_OVoKJro5pc,
  a11y_client_0xrnkKCxbb,
  core_fetch_Ny5VEdu3HH,
  datadog_client_7ANTQbYUMg,
  error_handler_xwgO4KJDpl,
  ks_toast_client_0cfbG3g52O,
  vue_query_6kKch8dzYS,
  defaults_M8ptihKv0z
]